/*********************************************************
NAV BUTTON STYLE
**********************************************************/
.nav-button {
    border: 10px black;
    display: inline-block;
    height: 50px;
    width: 50px;
    margin: 5px 20px 5px 0;
    position: fixed;
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 98;
  }

  .nav-button span {
    display: block;
    position: absolute;
    height: 4px;
    width: 40px;
    background: white;
    /* border-radius: 9px; */
    opacity: 1;
    left: 10px;
    
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  .nav-button span:nth-child(1) {
    top: 15px;
  }
  .nav-button span:nth-child(2),
  .nav-button span:nth-child(3) {
    top: 25px;
  }
  .nav-button span:nth-child(4) {
    top: 35px;
  }

  .nav-button.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  
  .nav-button.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .nav-button.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .nav-button.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  /*********************************************************
NAVBAR STYLE
**********************************************************/


.navSection {
  background: url('../img/grain2.png'), rgba(76, 72, 72, 0.523);
  backdrop-filter: blur(10px);
	z-index: 3;
	background-size: 100% 3px, 6px 100%;
  
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
}

.blur {
  position: absolute;
}

.nav-link {
  font-family: var(--main-outline-font);
  // font-weight: bolder;
  -webkit-text-size-adjust: 100%;
  text-align: center;
  // text-shadow: 3px 2px 2px black;
  /* opacity: 0; */
  color: white;
  padding: 1rem 0;
}

.nav-link1,
.nav-link2,
.nav-link3 {
 
 color: white;
 
 
 
}

.nav-link1:hover,
.nav-link2:hover,
.nav-link3:hover {
  transform: scale(1.1);
  font-family: var(--main-solid-font);
  transition: all .5s ease;
  /* animation: distort 1s infinite alternate-reverse; */
  cursor: pointer;
}

.item-show {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  max-height: 100vh;
  z-index: -99;
}
