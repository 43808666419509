/*********************************************************
BACKDROP STYLES
**********************************************************/

// .backdrop-2a,
// .backdrop-3a {
//   background: url('../img/grain.png') center repeat, url('../img/wallOverlay2.svg') top repeat;
//   background-size: 50%;
//   position: absolute;
//   height: inherit;
//   width: 100%;
//   overflow: visible;
//   transform:rotateY(-40deg);
//   -webkit-transform:rotateY(-40deg);
//   -moz-transform:rotateY(-40deg);
//   -ms-transform:rotateY(-40deg);
//   -o-transform:rotateY(-40deg);
// }

.backdrop-2a {
  animation: blinds 2s ease-in-out;
  animation-iteration-count: 1;
  -webkit-animation: blinds 2s ease-in-out;
  z-index: -98;
  
}

.backdrop-3a {
  animation: blinds2 2s ease-in-out;
  -webkit-animation: blinds2 2s ease-in-out;
  background-size: 10% 2%;
  transform: rotateY(0) rotateX(-75deg) translateY(-50%);
  -webkit-transform: rotateY(0) rotateX(-75deg) translateY(-50%) translateZ(-200px);
  -moz-transform: rotateY(0) rotateX(-75deg) translateY(-50%);
  -ms-transform: rotateY(0) rotateX(-75deg) translateY(-50%);
  -o-transform: rotateY(0) rotateX(-75deg) translateY(-50%);
  z-index: -98;
}

.backdrop-2b,
.backdrop-3b {
  background: url('../img/sillouette2.png') bottom no-repeat,#d4d4d4;
  background-size: contain;
  mask-position: top;
  mask-repeat: repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: inherit;
}

.backdrop-2b {
  mask-image: url('../img/wallOverlay.svg');
  animation: mask 4s ease-in-out forwards ;
  -webkit-animation: mask 4s ease-in-out forwards;
  z-index: -97;
}

.backdrop-3b {
  background-size: 200%;
  transform: rotateY(0) rotateX(50deg);
  mask-image: url('../img/cube.svg');
  mask-position: top;
  mask-size: 25px 4px;
  animation: mask2 2s ease-in forwards;
  -webkit-animation: mask2 2s ease-in forwards;
  -webkit-transform: rotateY(0) rotateX(50deg); 
  -moz-transform: rotateY(0) rotateX(50deg);
  -ms-transform: rotateY(0) rotateX(50deg);
  -o-transform: rotateY(0) rotateX(50deg);
  z-index: -98;
  filter: invert(100%);
}

.reverse-animation {
  animation: blinds2 2s ease-in-out;
  -webkit-animation: blinds2 2s ease-in-out;
  animation-direction: reverse;
}

.reverse-animation2 {
  animation: mask2 4s ease-in forwards;
  -webkit-animation: mask2 4s ease-in forwards;
  animation-direction: reverse;
}

@keyframes shadow3 {
  0%{
    background: url('../img/sillouette2.png') bottom no-repeat, 
    url('../img/sillouette_blue.png') bottom left no-repeat, 
    url('../img/sillouette_red.png') bottom right no-repeat,
    #d4d4d4;
    background-size: contain;
    
  }
  100% {
    background: url('../img/sillouette2.png') bottom no-repeat, 
    url('../img/sillouette_blue.png') bottom center no-repeat, 
    url('../img/sillouette_red.png') bottom center no-repeat,
    #d4d4d4;
    background-size: contain;
  }
  
}

