@font-face {
    font-family: 'oswaldreg';
    src: url('./fonts/oswald_font/oswald-regular-webfont.woff'); 
    font-weight: normal;
  }
  @font-face {
    font-family: 'oswaldbold';
    src: url('./fonts/oswald_font/oswald-bold-webfont.woff');
  }
  @font-face {
    font-family: 'electronica_display_outline';
    src: url('./fonts/electronica_displayoutline_macroman/electronica_display_outline-webfont.woff') format('woff2'),
         url('./fonts/electronica_displayoutline_macroman/electronica_display_outline-webfont.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'electronica_display_solid';
    src: url('./fonts/electronica_displaysolid_macroman/electronica_display_solid-webfont.woff') format('woff2'),
         url('./fonts/electronica_displaysolid_macroman/electronica_display_solid-webfont.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  :root {
    --main-outline-font: 'electronica_display_outline', sans-serif;
    --main-solid-font: 'electronica_display_solid', sans-serif;
    --screen-width: 100vw;
  }
  
  /********************************************************
  MAIN ELEMENT STYLES
  *********************************************************/
  *{
    overflow: hidden;
    margin: 0;
    -webkit-font-smooth: always;
  }
  
  h1, h2, h3, h6 {
    font-family: var(--main-outline-font);
    color: black;
    
  }
  
  p {
    font-family: 'oswaldreg', sans-serif;
    margin: 0;
  
  }
  a {
      color: inherit;
      text-decoration: none;
    }
  
  a:visited {
      text-decoration: none;
  }
  
  a:hover {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
  }
  
  ul {
    list-style: none;
  }
  
  header {
      background-color: black;
      margin: 0 auto;
      position: relative;
      height: 100vh; 
      width: 100%;
      max-height: 100vh;
  }  
  
  body {
      background-color: black;
      // width: 100%;
    
    
  }
  
  
  .App {
    // perspective: 1000px;
    width: 100%;
    height: 100vh;
  }
  
  
    .wrapper {
        display: flex;
        flex-direction: column;
    }
  
    .content {
      flex: 1 0 auto;
    }
  
    .section {
      margin: 9rem 1rem;
    }

    .overlay2 {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../img/pattern2.png') repeat top left;
      background-size: 3px;
      opacity: 0.9;
      z-index: 0;
    }
/*******************************************
  ANIMATION KEYFRAMES
*******************************************/

@keyframes blinds {
    0% {
      transform: rotateY(0);
      background-size: 5%;
    }
  
    100% {
      transform: rotateY(-40deg);
      background-size: 50%;
    }
  }
  
  @keyframes blinds2 {
    0% {
      transform: rotateY(-40deg) rotateX(0) translateY(0);
      background-size: 50%;
      -webkit-transform: rotateY(-40deg) rotateX(0) translateY(0);
      -moz-transform: rotateY(-40deg) rotateX(0) translateY(0);
      -ms-transform: rotateY(-40deg) rotateX(0) translateY(0);
      -o-transform: rotateY(-40deg) rotateX(0) translateY(0);
    }
    100% {
      background-size: 10%;
      transform: rotateY(0) rotateX(-75deg) translateY(-50%);
      -webkit-transform: rotateY(0) rotateX(-75deg) translateY(-50%);
      -moz-transform: rotateY(0) rotateX(-75deg) translateY(-50%);
      -ms-transform: rotateY(0) rotateX(-75deg) translateY(-50%);
      -o-transform: rotateY(0) rotateX(-75deg) translateY(-50%);
    }
  }
  
  @keyframes mask {
    0% {
     mask-size: 2px 1px;
    }
    30% {
    // transform: rotateY(40deg);
    // -webkit-transform: rotateY(40deg);
    //   -moz-transform: rotateY(40deg);
    //   -ms-transform: rotateY(40deg);
    //   -o-transform: rotateY(40deg);
    }
    100%{
      transform: rotateY(10deg);
      mask-size: 20px 20px;
      filter: invert(100%);
    }
  }
  
  @keyframes mask2 {
    0%{
      
      mask-image: url('../img/wallOverlay.svg');
      mask-size: 70px 1px;
      transform: rotateY(10deg) translateX(0) scale(1);
      -webkit-transform: rotateY(10deg) translateX(0) scale(1);
      -moz-transform: rotateY(10deg) translateX(0) scale(1);
      -ms-transform: rotateY(10deg) translateX(0) scale(1);
      -o-transform: rotateY(10deg) translateX(0) scale(1);
  }
  50% {
    mask-image: url('../img/cube.svg');
  }
  100% {
    background-size: 100%;
    mask-size: 10px 1px;
    transform-origin: bottom;
    transform: rotateY(0) rotateX(90deg) scale(2);
    -webkit-transform: rotateY(0) rotateX(90deg) scale(2);
    -moz-transform: rotateY(0) rotateX(90deg) scale(2);
    -ms-transform: rotateY(0) rotateX(90deg) scale(2);
    -o-transform: rotateY(0) rotateX(90deg) scale(2);
  }
  }
  
  @keyframes project-mask {
    0% {
      background: url("../img/IMG_1840.jpg") bottom no-repeat,#d4d4d4;
      transform: skew(0);
      mask-size: 2px 6px;
      -webkit-transform: skew(0);
      -moz-transform: skew(0);
      -ms-transform: skew(0);
      -o-transform: skew(0);
  }
    1% {
      background: linear-gradient(to left, rgb(18, 31, 207, 0.3) 0%, rgb(0, 255, 0, 0.3) 53%, rgb(207, 21, 18, 0.5) 97%), #d4d4d4;
      transform:translateY(-2rem);
      -webkit-transform:translateY(-2rem);
      -moz-transform:translateY(-2rem);
      -ms-transform:translateY(-2rem);
      -o-transform:translateY(-2rem);
  }
    2% {
      background: url("../img/IMG_1840.jpg") bottom no-repeat,#d4d4d4;
      background-size: contain;
      transform: translateY(0) skew(-10deg, 0) rotateY(90deg);
      -webkit-transform: translateY(0) skew(-10deg, 0) rotateY(90deg);
      -moz-transform: translateY(0) skew(-10deg, 0) rotateY(90deg);
      -ms-transform: translateY(0) skew(-10deg, 0) rotateY(90deg);
      -o-transform: translateY(0) skew(-10deg, 0) rotateY(90deg);
  }
    3% {
      transform: skew(0,0) rotateY(0);
      -webkit-transform: skew(0,0) rotateY(0);
      -moz-transform: skew(0,0) rotateY(0);
      -ms-transform: skew(0,0) rotateY(0);
      -o-transform: skew(0,0) rotateY(0);
  }
    8% {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
  }
    9% {
      background: linear-gradient(to left, rgb(18, 31, 207, 0.3) 0%, rgb(0, 255, 0, 0.3) 53%, rgb(207, 21, 18, 0.5) 97%), url("../img/IMG_1840.jpg") bottom no-repeat,#d4d4d4; 
      transform: translate(-5rem, 1rem);
      -webkit-transform: translate(-5rem, 1rem);
      -moz-transform: translate(-5rem, 1rem);
      -ms-transform: translate(-5rem, 1rem);
      -o-transform: translate(-5rem, 1rem);
  }
    10% {
      background: url("../img/IMG_1840.jpg") bottom no-repeat,#d4d4d4;
      background-size: contain;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
  }
    11% {
    transform: skew(0) translateY(0);
    -webkit-transform: skew(0) translateY(0);
    -moz-transform: skew(0) translateY(0);
    -ms-transform: skew(0) translateY(0);
    -o-transform: skew(0) translateY(0);
  }
    12% {
      transform: translateY(0);
      mask-size: 1px 1px;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
  }
  100% {
    background: url("../img/IMG_1840.jpg") bottom no-repeat,#d4d4d4;
    background-size: contain;
    mask-size: 1px 1px;
    transform: skew(0);
    -webkit-transform: skew(0);
    -moz-transform: skew(0);
    -ms-transform: skew(0);
    -o-transform: skew(0);
  }
  }
  
  @keyframes headlineGrow {
    0% {
      max-height: 0;
      width: 0;
      opacity: 0;
    }
    100% {
      max-width: auto;
      width: auto;
      opacity: 1;
    }
  }


  .crt::after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(18, 16, 16, 0.1);
      opacity: 0;
      z-index: 2;
      pointer-events: none;
    }
    .crt::before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
      z-index: 2;
      background-size: 100% 4px, 3px 100%;
      pointer-events: none;
    }
    .crt {
      animation: textShadow 1.6s infinite;
    }
  